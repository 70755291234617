import React from "react";
import styled from "styled-components";
import { GetStarted } from "./Footer";
import { StyledA, CommonColorButton } from "./common";

const BannerContainer = styled(GetStarted)`
  top: initial;
  width: inherit;
  max-width: initial;
  margin-top: 4rem;
`;

export const Banner = () => (
  <BannerContainer>
    <div>
      <h3>Let’s get started!</h3>
      <p style={{ color: "#FFF" }}>#1 Online Receipt Maker</p>
    </div>
    <StyledA href="https://receiptmakerly.com/pricing/">
      <CommonColorButton color="#635BFF" bgColor="#FFF" hoverBg="#FFF">
        Get Started
      </CommonColorButton>
    </StyledA>
  </BannerContainer>
);
