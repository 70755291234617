import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

const ContentNavigator = styled.div`
  padding: 12px;
  min-height: 42px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  background-color: ${props =>
    props.isActive ? `rgba(0, 206, 180, 0.1)` : `#fff`};
`
const SideNav = styled.div`
  align-self: flex-start;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 70px;
  max-height: calc(100vh - 70px);
  overflow: auto;
  @media (max-width: 900px) {
    display: none;
  }
`
const NavHeader = styled.p`
  margin: 0;
  color: #3d4d69;
  padding: 10px 10px 20px;
  font-size: 24px;
`
const StyledA = styled.a`
  text-decoration: none;
  color: #3d4d69;
`

const Headings = ({ headings, activeId }) => (
  <div>
    {headings.map(heading => (
      <ContentNavigator
        key={heading.id}
        isActive={heading.id === activeId ? true : false}
      >
        <StyledA
          href={`#${heading.id}`}
          onClick={e => {
            e.preventDefault()
            document.querySelector(`#${heading.id}`).scrollIntoView({
              top: "100px",
              behavior: "smooth",
            })
          }}
        >
          {heading.title}
        </StyledA>
        {heading.items.length > 0 && (
          <div>
            {heading.items.map(child => (
              <ContentNavigator
                key={child.id}
                isActive={heading.id === activeId ? true : false}
              >
                <StyledA
                  href={`#${child.id}`}
                  onClick={e => {
                    e.preventDefault()
                    document.querySelector(`#${child.id}`).scrollIntoView({
                      behavior: "smooth",
                    })
                  }}
                >
                  {child.title}
                </StyledA>
              </ContentNavigator>
            ))}
          </div>
        )}
      </ContentNavigator>
    ))}
  </div>
)

const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = useState([])

  React.useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll("#toc h2"))

    // Created a list of headings, with H3s nested
    const newNestedHeadings = getNestedHeadings(headingElements)
    setNestedHeadings(newNestedHeadings)
  }, [])

  return { nestedHeadings }
}

const getNestedHeadings = headingElements => {
  const nestedHeadings = []

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading

    if (heading.nodeName === "H2") {
      nestedHeadings.push({ id, title, items: [] })
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      })
    }
  })

  return nestedHeadings
}

const useIntersectionObserver = setActiveId => {
  const headingElementsRef = useRef({})
  useEffect(() => {
    const callback = headings => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement
        return map
      }, headingElementsRef.current)

      // Get all headings that are currently visible on the page
      const visibleHeadings = []
      Object.keys(headingElementsRef.current).forEach(key => {
        const headingElement = headingElementsRef.current[key]
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
      })

      const getIndexFromId = id =>
        headingElements.findIndex(heading => heading.id === id)

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id)
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        )

        setActiveId(sortedVisibleHeadings[0].target.id)
      }
    }

    const observer = new IntersectionObserver(callback, {
      root: document.querySelector("iframe"),
      rootMargin: "-110px 0px -40% 0px",
    })

    const headingElements = Array.from(document.querySelectorAll("h2, h3"))

    headingElements.forEach(element => observer.observe(element))

    return () => observer.disconnect()
  }, [setActiveId])
}

export const TableOfContents = () => {
  const [activeId, setActiveId] = useState()
  const { nestedHeadings } = useHeadingsData()
  useIntersectionObserver(setActiveId)

  return (
    <SideNav aria-label="Table of contents">
      <NavHeader>Guide Topics</NavHeader>
      <Headings headings={nestedHeadings} activeId={activeId} />
    </SideNav>
  )
}
