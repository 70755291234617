import styled from "styled-components";
import { Link } from "gatsby"

export const StyledMain = styled.main`
  display: grid;
  justify-content: center;
  padding: 16px 0;
  @media (max-width: 1024px) {
    padding: 16px 0;
  }
`
export const BeforeTocSection = styled.div`
  display: grid;
  justify-content: center;
  padding: 0 9%;
  @media (max-width: 1380px) {
    padding: 0 6%;
  }
  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`
export const TocContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
`
export const TocH2 = styled.h2`
  scroll-margin: 90px;
  font-size: 27px;
  margin-bottom: 0.4em;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
export const TocH3 = styled.h3`
  scroll-margin: 90px;
  font-family: "Montserrat", sans-serif;
  color: #3d4d69;
  font-size: 23px;
  margin-bottom: 0.4em;
  @media (max-width: 575px) {
    font-size: 16px;
  }
`
export const TocH4 = styled.h4`
  scroll-margin: 90px;
  font-family: "Khula", sans-serif;
  color: #3d4d69;
  font-size: 20px;
  margin-top: 1.175rem;
`
export const TocText = styled.p`
  line-height: 1.6;
  word-spacing: 2px;
  margin: 13px 0 8px;
`
export const SectionTitle = styled.h2`
  margin: 1em auto 20px auto;
  color: #1f2939;
  font-size: 34px;
  text-align: center;
  max-width: 980px;
  @media (max-width: 575px){
    font-size: 20px;
  }
`

export const ColumnsMobileView = styled.div`
  display: none;
  grid-template-columns: 1fr;
  border: 1px solid #f4f7fa;
  @media (max-width: 575px) {
    display: grid;
  }
`
export const ColumnItem = styled.div`
  border: 1px solid #f4f7fa;
  background-color: ${props => (props.heading ? `#f4f7fa` : `#FFF`)};
  padding: 12px;
  color: #161f32;
  font-size: ${props => (props.heading ? `20px` : `18px`)};
  font-weight: ${props => (props.heading ? `600` : `400`)};
  @media (max-width: 575px) {
    font-size: ${props => (props.heading ? `16px` : `14px`)};
  }
`
export const TableContainer = styled.div`
  @media (max-width: 500px) {
    overflow: auto;
  }
`
export const CommonAnchor = styled.a`
  color: ${props => (props.sameColor ? `#3d4d69` : `#4D94FB`)};
  text-decoration: none;
`
export const TOCImageContainer = styled.div`
display: grid;
grid-template-columns: ${props => props.columns || `minmax(auto, 720px)`};
justify-content: center;
padding: 25px 0;
`
export const CommonLink = styled(Link)`
  color: ${props => (props.sameColor ? `#1f2939` : `#4D94FB`)};
  text-decoration: none;
`
export const thCss = {
  borderWidth: "2px",
  borderColor: "#f4f7fa",
  borderStyle: "solid",
  padding: "10px",
  textAlign: "center",
  backgroundColor: `#f4f7fa`,
  color: "#1F2939",
  fontSize: `14px`,
  fontWeight: `600`,
}
export const tdCss = {
  borderWidth: "2px",
  borderColor: "#f4f7fa",
  borderStyle: "solid",
  padding: "3px",
  textAlign: "center",
  backgroundColor: `#FFF`,
  color: `#1F2939`,
  fontSize: `14px`,
}
export const tableCss = {
  width: "100%",
  backgroundColor: "#ffffff",
  borderCollapse: "collapse",
  borderWidth: "2px",
  borderColor: "#f4f7fa",
  borderStyle: "solid",
  color: "#2E3A4B",
  marginTop: "1.45rem",
}