import React, { useState } from "react";
import styled from "styled-components";
import SEO from "../components/SEO";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Accordion from "../components/accordion";
import { Banner } from "../components/banner";
import {
  TitleSection,
  TitleSubText,
  ColoredSpan,
  StyledLink,
  StyledA,
  TitleH2Comp,
  TemplateH2,
  TemplateH3,
  CardContainer,
  CommonCard,
  CardH4,
  CardTitle,
  CardP,
  ReviewCardContainer,
  ReviewCard,
  ReviewLowerSection,
  ReviewCardLowerText,
  ImageSection,
  CommonArrowButton,
  ButtonArrow,
  ImageTitleH2,
  FaqContainer,
} from "../components/common";
import {
  StyledMain,
  BeforeTocSection,
  TocContainer,
  TocH2,
  TocH3,
  TocText,
  ColumnsMobileView,
  ColumnItem,
  TableContainer,
  thCss,
  tdCss,
  tableCss,
  SectionTitle,
  CommonAnchor,
  CommonLink,
  TOCImageContainer,
} from "../components/ultimateGuide";
import {
  Container,
  ExtendedContainer,
  BulletList,
  BulletRow,
  NumberedList,
  NumberedRow,
  ImageFigure,
  Image,
  SmallImageFigure,
  SmallImage,
} from "../components/templateStyle";
import { TableOfContents } from "../components/TableOfContents";
import featureImage from "../images/receiptmakerly-banner.png";
import signInUI from "../images/sign-in-ui-receiptmakerly.png";
import templatesUI from "../images/online-custom-receipts.png";
import templatesEditorUI from "../images/templates-editor-ui-receiptmakerly.png";
import visitArrow from "../images/arrow.svg";
import buttonArrow from "../images/arrow-white.svg";
import chooseTemplate from "../images/Choose template.png";
import customizeTemplate from "../images/Customise template.png";
import DownloadOrSendEmail from "../images/Download or send email.png";
import iconGenericSimpleReceipts from "../images/icon-generic-simple-receipts.png";
import iconGroceryReceiptsTemplates from "../images/icon-grocery-receipts-templates.png";
import iconParkingReceiptsTemplates from "../images/icon-parking-receipts-templates.png";
import iconPetrolGasReceiptsTemplates from "../images/icon-petrol-gas-receipts-templates.png";
import iconPharmacyReceiptsTemplates from "../images/icon-pharmacy-receipts-templates.png";
import iconPhoneInternetReceipts from "../images/icon-phone-internet-receipts.png";
import iconRestaurantReceiptsTemplates from "../images/icon-restaurant-receipts-templates.png";
import iconSalesReceiptsTemplates from "../images/icon-sales-receipts-templates.png";
import iconTaxiReceiptsTemplates from "../images/icon-taxi-receipts-templates.png";
import iconVehicleTollReceipts from "../images/icon-vehicle-toll-receipts.png";

const UpperMainContainer = styled.div`
  display: grid;
`;
const FeatureOuterContainer = styled.div`
  margin-top: 3em;
  @media (max-width: 800px) {
    margin: 2em auto 0;
  }
`;
const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 350px));
  gap: 15px;
  padding: 1.5em 2.7em;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid #e7eff5;
  box-shadow: 0px 20px 40px #94a6ce1a;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5em 25px;
  }
  @media (max-width: 800px) {
    grid-template-columns: minmax(300px, 350px);
    justify-content: center;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;
const FeatureCard = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr;
  gap: 2em;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1100px) {
    gap: 20px;
  }
`;
const DarkSection = styled.div`
  display: grid;
  grid-template-columns: 35% minmax(620px, 700px);
  justify-content: start;
  grid-gap: 30px;
  padding: 80px 8%;
  background-color: #2d2d51;
  color: #fff;
  @media (max-width: 1100px) {
    grid-template-columns: 280px 1fr;
    padding: 80px 6%;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 575px) {
    padding: 50px 8%;
  }
`;
const DarkLeftSection = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;
const DarkH2 = styled.h2`
  max-width: 280px;
  font-weight: 600;
  @media (max-width: 575px) {
    text-align: center;
    max-width: initial;
  }
`;
const DarkBar = styled.div`
  width: 4px;
  margin: 30px 0;
  background-color: #424262;
  border-radius: 2px;
  @media (max-width: 800px) {
    display: none;
  }
`;
const DarkAppSectionContainer = styled.div`
  display: grid;
  gap: 50px 0;
  @media (max-width: 575px) {
    gap: 80px 0;
  }
`;
const DarkAppSection = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
  gap: 0 10%;
  @media (max-width: 1100px) {
    gap: 0 7%;
  }
  @media (max-width: 575px) {
    grid-template-columns: minmax(220px, 260px);
    justify-content: center;
  }
`;
const LargeNumber = styled.div`
  color: #424262;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 3.6px;
  margin-bottom: 10px;
`;
const DarkText = styled.p`
  color: #ffffffb3;
`;
const VisitLinkSection = styled.div`
  display: grid;
  grid-template-columns: auto 25px;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
  &:hover ${ButtonArrow} {
    width: 22px;
  }
`;
const VisitLink = styled(Link)`
  color: #f77b63;
  text-decoration: none;
  font-size: 18px;
  margin-right: auto;
  &:hover {
    color: #ef755d;
  }
`;
const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 60%);
  justify-content: center;
  padding: 3em 30px;
`;
const VideoSection = styled.div`
  overflow: hidden;
  padding-bottom: 55%;
  position: relative;
  height: 0px;
`;
const StyledIframe = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`;
const TemplateTypeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1em;
  justify-content: space-between;
  /* padding: 2em;
  border-radius: 20px;
  background: #e7eff5; */
  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, 170px);
    justify-content: center;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
    gap: 1.5em 4%;
  }
`;
const TemplateTypeCard = styled.div`
  display: grid;
  gap: 13px;
  justify-items: center;
  text-align: center;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px 15px;
  border: 1px solid #e7eff5;
  box-shadow: 0px 20px 40px #94a6ce1a;
  @media (max-width: 575px) {
    gap: 5px;
  }
`;
const Title = styled.h1`
  margin-bottom: 20px;
`;
const Subtitle = styled.h2`
  margin-top: 0;
  margin-bottom: 5px;
`;
const LandingBulletList = styled(BulletList)`
  padding-inline-start: 0;
  margin-bottom: 10px;
`;
const LandingTocBulletTitle = styled(BulletRow)`
  margin: 1rem 0 0.6em 0;
  padding-top: 0;
  padding-bottom: 0;
`;

const LandingNumberedList = styled(NumberedList)`
  padding-inline-start: 0;
  margin-block: 0;
`;
const LandingTocNumberedTitle = styled(NumberedRow)`
  margin: 1rem 0 0.6em 0;
  display: -webkit-box;
  @media (max-width: 575px) {
    width: 330px;
  }
  @media (max-width: 395px) {
    width: 320px;
  }
`;
const Section = styled.section`
  margin-top: 2rem;
`;
const StyledImage = styled.img`
  max-width: 1068px;
`;
// markup
const IndexPage = ({ location }) => {
  return (
    <>
      <SEO
        title="#1 receipt maker & online receipt generator"
        description="An intuitive online receipt maker and receipt generator for businesses. Use customizable templates to generate receipts of various types for your customers"
        keywords="Receiptmakerly,
        Receiptmakerly,
        receipt maker,
        receipt generator,
        make a receipt,
        make receipt,
        online receipt maker,
        online receipt generator,
        create a receipt,
        receipt writer,
        custom receipts,
        receipts online,
        receipt maker online,
        create receipt,
        make receipt online"
        pathname={location.pathname && location.pathname}
        sofwareApp
      />
      <Container>
        <ExtendedContainer padding="0 30px">
          <TitleSection>
            <Title>#1 Receipt Maker & Online Receipt Generator</Title>
            <Subtitle>with 50+ Customizable Templates</Subtitle>
            <TitleSubText>
              An intuitive receipt maker for your business with premade
              customizable templates Receiptmakerly is straightforward and easy
              to use.
            </TitleSubText>
            <StyledLink to="/pricing/">
              <CommonArrowButton>
                <div>Get Started</div>
                <ButtonArrow>
                  <img
                    src={buttonArrow}
                    alt="arrow"
                    style={{ marginLeft: `10px`, maxWidth: `36px` }}
                  />
                </ButtonArrow>
              </CommonArrowButton>
            </StyledLink>
          </TitleSection>

          <StaticImage
            src="../images/receiptmakerly-banner.png"
            alt="#1 receipt maker & online receipt generator"
            loading="eager"
          />

          <FeatureOuterContainer>
            <FeatureContainer>
              <FeatureCard>
                <img
                  src={chooseTemplate}
                  alt="choose template"
                  width="100%"
                  style={{ maxWidth: `45px` }}
                />
                <div>
                  <CardTitle>Choose Template</CardTitle>
                  <CardP>Choose your favorite template from the list.</CardP>
                </div>
              </FeatureCard>
              <FeatureCard>
                <img
                  src={customizeTemplate}
                  alt="customize receipt template"
                  width="100%"
                  style={{ maxWidth: `45px` }}
                />
                <div>
                  <CardTitle>Customize template</CardTitle>
                  <CardP>Customize it with the required information. </CardP>
                </div>
              </FeatureCard>
              <FeatureCard>
                <img
                  src={DownloadOrSendEmail}
                  alt="Download or send email"
                  style={{ maxWidth: `45px` }}
                />
                <div>
                  <CardTitle>Download Or Send Email</CardTitle>
                  <CardP>Download or send the receipt to your client</CardP>
                </div>
              </FeatureCard>
            </FeatureContainer>
          </FeatureOuterContainer>
        </ExtendedContainer>
        <ExtendedContainer padding="0 30px">
          <Section>
            <TemplateH2 id="receipts-templates-for-every-type-of-business">
              Receipts <ColoredSpan> Templates </ColoredSpan> for Every Type of
              Business
            </TemplateH2>
            <TemplateTypeContainer>
              <TemplateTypeCard>
                <img
                  src={iconGenericSimpleReceipts}
                  alt="Generic Simple Receipts"
                  width="70px"
                />
                <CardP>Generic Simple Receipts</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconSalesReceiptsTemplates}
                  alt="Sales Receipts Templates"
                  width="70px"
                />
                <CardP>Sales Receipts Templates</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconTaxiReceiptsTemplates}
                  alt="Taxi Receipts Templates"
                  width="70px"
                />
                <CardP>Taxi Receipts Templates</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconParkingReceiptsTemplates}
                  alt="Parking Receipts Templates"
                  width="70px"
                />
                <CardP>Parking Receipts Templates</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconRestaurantReceiptsTemplates}
                  alt="Restaurant Receipts Templates"
                  width="70px"
                />
                <CardP>Restaurant Receipts Templates</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconPetrolGasReceiptsTemplates}
                  alt="Petrol/Gas Receipts Templates"
                  width="70px"
                />
                <CardP>Petrol/Gas Receipts Templates</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconVehicleTollReceipts}
                  alt="Vehicle Toll Receipts"
                  width="70px"
                />
                <CardP>Vehicle Toll Receipts</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconGroceryReceiptsTemplates}
                  alt="Grocery Receipts Templates"
                  width="70px"
                />
                <CardP>Grocery Receipts Templates</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconPhoneInternetReceipts}
                  alt="Phone, Internet Receipts"
                  width="70px"
                />
                <CardP>Phone, Internet Receipts</CardP>
              </TemplateTypeCard>
              <TemplateTypeCard>
                <img
                  src={iconPharmacyReceiptsTemplates}
                  alt="Pharmacy Receipts Templates"
                  width="70px"
                />
                <CardP>Pharmacy Receipts Templates</CardP>
              </TemplateTypeCard>
            </TemplateTypeContainer>
          </Section>
        </ExtendedContainer>

        <Section>
          <TemplateH2>
            Create Receipt with Online Receipt Generator Receiptmakerly
          </TemplateH2>
          <TocText>
            It is convenient to create receipts for different types of
            businesses using premade templates of Receiptmakerly. Let’s see the
            step-by-step process to generate receipts with Receiptmakerly.
          </TocText>

          <LandingNumberedList>
            <NumberedRow>
              <strong>
                After finishing the signup process, log in to your account.
              </strong>

              {/* <SmallImageFigure>
                <StaticImage
                  src="../images/sign-in-ui-receiptmakerly.svg"
                  alt="sign-in-ui-receiptmakerly"
                />
              </SmallImageFigure> */}
              <SmallImage src={signInUI } alt="sign-in-ui-receiptmakerly" />
            </NumberedRow>

            <NumberedRow>
              <strong>
                Now, select a suitable receipt template for your business.{" "}
              </strong>
              {/* <ImageFigure>
                <StaticImage src="../images/online-custom-receipts.png" alt="receipt template in Receiptmakerly" />
              </ImageFigure> */}
              <Image
                src={templatesUI}
                alt="receipt template in Receiptmakerly"
              />
            </NumberedRow>

            <NumberedRow>
              <strong>
                Customize the template with the required information described
                above under key components.{" "}
              </strong>
              {/* <ImageFigure>
                <StaticImage src="../images/online-custom-receipts.png" alt="customize receipt template in Receiptmakerly" />
              </ImageFigure> */}
              <Image
                src={templatesEditorUI}
                alt="customize receipt template in Receiptmakerly"
              />
            </NumberedRow>
          </LandingNumberedList>
          {/* 
        <VideoContainer>
          <VideoSection>
            <StyledIframe
              width="100%"
              src="https://www.youtube.com/embed/rV22-0yqui8"
              title="Bills 99 - Best Receipt Maker"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </VideoSection>
        </VideoContainer> */}
        </Section>
        <Section>
          <TemplateH2>Frequently Asked Questions</TemplateH2>

          <FaqContainer>
            <Accordion
              title="Can I put my own logo on the templates of the receipt maker?"
              content="Yes, you can add your logo to the receipt template. Please note that all the templates do not have the logo-adding feature. So, select the template that contains the logo and replace that with your one."
            />
            <Accordion
              title="How do I send a receipt with this receipt maker?"
              content="Once you have created the receipt using your favorite template, you have the option to download the receipt in both pdf and image(png) format. If you want, you can directly send the receipt using the receiver's email address."
            />
            <Accordion
              title="Can I generate a PDF receipt?"
              content="Yes, you can generate receipts both in pdf and image format. Get the one you need in particular."
            />

            <Accordion
              title="How do I charge taxes using the receipt maker?"
              content="There is an option to add the percentage of the tax. Once you add the percentage, Receiptmakerly will automatically calculate the amount."
            />

            <Accordion
              title="Can I save my receipts and customer details?"
              content="Yes. Your receipts are automatically saved to your receipts list. You can also re-use this receipt to generate the same kind of receipt."
            />

            <Accordion
              title="What kind of receipts can I make using Receiptmakerly?"
              content="We offer a wide range of receipt designs and templates to choose from. Moreover, using Receiptmakerly you can create customized receipts for your restaurant business, salon, health institute, parking lot, taxi business, gas receipts, mobile bill, and internet bill. Whether you belong to the US, Canada, France, Australia, UK, Germany, India, or any other country, Receiptmakerly lets you create uniquely tailored receipts for your users."
            />

            <Accordion
              title="Do I have to pay any money for creating receipts?"
              content="There are weekly, monthly, and annual membership plans available. To get the templates with additional features, you need to subscribe to any of our plans. However, if you do not like the tool, there is a 14 days refund policy, and you can cancel anytime."
            />

            <Accordion
              title="Can I create receipts for any custom brand with this receipt maker?"
              content="Of course, you can do that by using our templates. Just customize the templates according to your interest and make a custom receipt for yourself."
            />

            <Accordion
              title="What about my privacy?"
              content="Without a doubt, Receiptmakerly does not share client information with marketers or third parties or store your credit card information. Our payment processor Paddle securely handles all transactions. Indeed, we do not retain copies of the receipts you create, and we do not send unsolicited emails. However, for more clarification, please read our privacy policy."
            />

            <Accordion
              title="How does the Receiptmakerly receipt maker work?"
              content="Navigating Receiptmakerly is extremely simple and time-saving. Easily you can avail receipts in just three simple steps. Firstly, select the style and design of the receipt you like. Secondly, customize the receipt according to your preferences by changing the logo, fonts, entering text, bill amount, taxes, etc. Once everything is done, just save the receipt. Additionally, you can download, print, or directly email the receipt. Happy receipt generation!"
            />

            <Accordion
              title="Can I create a fake receipt?"
              content="According to our Terms of Use, no user can create any fake receipt. We encourage you to use the beautiful premade templates and customize those accordingly. "
            />

            <Accordion
              title="What is your support policy?"
              content="Our support applies only to our website – Receiptmakerly.com. We don’t provide technical support to other websites or applications. Most importantly, you can only change your receipts before saving them. Obviously, we hold no responsibility and are not accountable for any illegal or fraudulent activity conducted using receipts created by our platform. Moreover, it is mandatory to let us know in advance if you wish to cancel our subscription. You can use our support mail or instant chat support available on the website to get support."
            />

            <Accordion
              title="How do I change fonts?"
              content="While creating the receipt, you will get the option to choose fonts. Just choose the font from the drop-down."
            />
          </FaqContainer>
        </Section>
        <Banner />
        <Section>
          <SectionTitle>
            Detailed Guide to Online Receipt Maker with Customizable Templates
          </SectionTitle>

          <TocContainer>
            {/* <TableOfContents /> */}
            <StyledMain id="toc">
              <TocH2>What is a receipt?</TocH2>
              <TocText>
                A receipt is a written confirmation that an item has been
                transferred from one party to another. Receipts are issued in
                business-to-business and business-to-consumer transactions, and
                the receipts consumers generally receive from merchants and
                service providers.
              </TocText>

              <TocH2>Key components of a receipt?</TocH2>
              <TocText>
                Above all, the receipt you make for your clients gives an
                impression of your business. While creating a receipt, you
                should look after a few points so that your receipt stands out
                to your clients. Let’s have a look at the key components that a
                typical receipt should include.
              </TocText>
              <LandingBulletList>
                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Name and address:</TocH3>
                  <TocText>
                    A receipt should contain the name and address of the
                    business. It is also customary that the client's details
                    should also be added to the receipt.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Logo:</TocH3>
                  <TocText>
                    A logo represents not only a brand but also a business. Your
                    receipt should contain the logo/trademark of your business.
                    You can add a logo to your receipt using an online receipt
                    maker like Receiptmakerly.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Date and Time:</TocH3>
                  <TocText>
                    It is one of the most critical parts of the receipt. It is
                    even more vital that the product contains a return and
                    refund policy.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Receipt Number:</TocH3>
                  <TocText>
                    A receipt number will be generated only once complete
                    payment has been made in response to an invoice. These
                    numbers follow one another in sequence, and they are
                    dependent on the date and time that the invoice was paid.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Item List:</TocH3>
                  <TocText>
                    This section of the receipt contains the list of the items.
                    While creating an{" "}
                    <a href="https://receiptmakerly.com/itemized-receipt/">
                      itemized receipt
                    </a>{" "}
                    all the details of the products should be added along with
                    the price.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Location details:</TocH3>
                  <TocText>
                    Taxi receipts should contain the location details that
                    include pick up and drop off points. Distance traveled with
                    the GPS locations should also be added to the receipt.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>
                    Individual and Total Price:
                  </TocH3>
                  <TocText>
                    If the receipt is made for one or more items, individual
                    prices should be added. If there is a vat with it, that
                    should also be added.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Currency:</TocH3>
                  <TocText>
                    Currency is another essential factor of a good receipt. Use
                    a suitable currency for the receipt.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Payment Method:</TocH3>
                  <TocText>
                    There are several payment methods like cash, card, and bank.
                    However, a receipt should contain the payment method used
                    for the transaction.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>SKU</TocH3>
                  <TocText>
                    The stock-keeping unit or SKU is sometimes referred to as
                    the item part number. You can find this information outside
                    the product box or sometimes on your receipt. However, it
                    will not be found on the product instructions because the
                    numbers on the instructions are unique to the product. The
                    stock-keeping unit, often known as the SKU number, is a
                    seven-digit number that begins with the number 8. For
                    instance, 8000148.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Signature/Credentials:</TocH3>
                  <TocText>
                    This is the signature or credential of the representative
                    who has accepted the payment. Whether you are the seller or
                    a manager, do not forget to sign the receipt.
                  </TocText>
                </LandingTocBulletTitle>

                <LandingTocBulletTitle>
                  <TocH3 style={{ margin: `0` }}>Thank You Message:</TocH3>
                  <TocText>
                    You’ll find many receipts containing thank you messages at
                    the end. Besides, it’s a good way to value your customers
                    and invite them to come again.
                  </TocText>
                  <TocText>
                    <strong>
                      <em>
                        Note: The above-mentioned components may vary based on
                        the type of business. So, you should check the best
                        receipts in your industry and use a better one for you.
                      </em>
                    </strong>
                  </TocText>
                </LandingTocBulletTitle>
              </LandingBulletList>

              <TocH2>Why do you need receipts for your business?</TocH2>
              <TocText>
                Receipts are important for businesses of all sizes, and it is an
                integral part of every transaction. Online receipt makers have
                made the work easier for business owners. If you have not
                started generating receipts for your clients/customers, it’s
                time to take action.
              </TocText>
              <TocText>
                The following points can satisfy you why you should use the
                receipt for your business.
              </TocText>
              <TocH3>Proof of financial transactions:</TocH3>
              <TocText>
                Receipts are official records representing proof of a financial
                transaction or purchase. So, you can justify later regarding
                this transaction if any unwanted situation occurs. After making
                payments for products or services, consumers often get receipts
                as evidence that the transaction actually took place. Receipts
                typically contain details about the goods or services that were
                sold, such as quantity, price, and discounts. In some cases,
                receipts may also contain information on the payment method used
                during the transaction.
              </TocText>

              <TocH3> Increases brand image</TocH3>
              <TocText>
                Receipts are not necessarily the first thing that comes to mind
                when one thinks of marketing or branding, but if you can use
                them properly, you can turn them into exactly that. Once you add
                your business details with a beautiful logo, it will create an
                impression in customers' minds. A welcome message at the end of
                the receipt can attract customers more. However, it can also
                give you the benefit of indirect marketing.
              </TocText>

              <TocH3>Helps to manage refund and returns</TocH3>
              <TocText>
                This is a common phenomenon for almost all businesses of having
                refunds and return policies. A receipt is the only tool to help
                you understand the transaction's validity. For example, you sell
                digital goods and have a 30 days refund policy. A receipt will
                help you to verify the refund claim.
              </TocText>

              <TocH3>Generates customer happiness</TocH3>
              <TocText>
                If you supply your customer with receipts, you make it possible
                for them to pay you after you have provided a service, finished
                a job, or done other work for them, making their lives easier.
                Therefore, by supplying your consumer with a receipt, you
                provide them with a straightforward means to make payment.
              </TocText>
              <TocText>
                Not only that, but you also assist them in maintaining control
                of their financial situation while you are doing so. Invoices
                contain information on what was purchased, when, for what price,
                any late fees, and so on; this information is extremely useful
                for consumers both now and in the future when it comes to
                keeping track of their cash. Invoices contain information on
                what was purchased, when purchased, what price, etc.
              </TocText>

              <TocH3> Easy access from any place</TocH3>
              <TocText>
                Another advantage of online receipts is that you do not have to
                be chained to a desk to process them. As long as you have access
                to a device provided with internet connectivity, you will have
                all the information you require in the palm of your hand.
              </TocText>
              <TocText>
                So, let's say you're out and about conducting necessary errands
                for your company, and a client contacts you with a question
                about their bill; you can handle it there and then, keeping the
                customer pleased while also relieving some of the stress you've
                been feeling about the situation.
              </TocText>

              <TocH3>
                Online receipts are easy to create using receipt makers
              </TocH3>
              <TocText>
                If the thought of sending out receipts has put you off in the
                past because you believe they will be time-consuming, will
                require additional paperwork, and you just do not have enough
                hours in the day, we are happy to inform you that the situation
                has improved significantly in recent years.
              </TocText>
              <TocText>
                You may send a receipt to your consumers in seconds when you use
                an online receipt maker like Receiptmakerly.
              </TocText>

              <TocH3> Helps to get payment</TocH3>
              <TocText>
                Payment is the means of reciprocating any payment or online
                transactions. Receipts help you to get paid easily. If you are
                working with a client on a project, you can send a receipt with
                detailed transactions once you complete your task. Your client
                will know how much to pay and act accordingly.
              </TocText>

              <TocH3> Common Receipt Types</TocH3>
              <TocText>
                With the variation of the businesses, there are changes in the
                receipt types. If you use a restaurant receipt for your taxi
                bill, it won't look good. That’s why it is better you know the
                types of receipts and the best suitable one for you. Let’s look
                at the common receipt types available in receipt makers.
              </TocText>
              <BulletList>
                <BulletRow>
                  <strong>Restaurant receipt:</strong> This type of receipt
                  contains the list of food items ordered by the customer. If
                  there is any tax on the foods, that can also be added. These
                  receipts are essential for both the restaurant authority and
                  the customer.
                </BulletRow>

                <BulletRow>
                  <strong>Pharmacy receipt : </strong> The amount of money that
                  a consumer owes to a pharmacy is listed on the generic receipt
                  that pharmacies use to bill their customers. The charge may
                  include both the cost of any medication or commodities
                  purchased by the consumer and an additional fee for the
                  service (if applicable). On the receipt, there must be a
                  comprehensive listing of all material goods and the costs
                  associated with them. Additionally, this information and the
                  relevant fee need to be documented if a pharmacist or another
                  medical practitioner has provided a service for the consumer.
                </BulletRow>

                <BulletRow>
                  <strong>Taxi receipt/Rideshare receipt : </strong>{" "}
                  Taxi/rideshare drivers issue receipts using a template. It is
                  the proof of how much the passenger paid, how far they
                  traveled, and when they took the trip. Passengers may claim a
                  tax deduction if they have their taxi/rideshare receipts. With
                  the integration of Google Maps, the pick and drop-off location
                  can be added to the receipt.
                </BulletRow>

                <BulletRow>
                  <strong>Petrol/gas receipts: </strong>
                  These types of receipts are used by the pump owners who sell
                  petrol or gas for automobiles.
                </BulletRow>

                <BulletRow>
                  <strong>Grocery receipts: </strong>It is often found that
                  grocery shop owners write the list of items and their
                  respective amounts. After that, they manually calculate the
                  total amount from the customer. It’s the era of
                  digitalization, and the world is going paperless and getting
                  automated. It is now easy to create online receipts easily.
                </BulletRow>

                <BulletRow>
                  <strong>Toll receipts: </strong> A customer gets this receipt
                  while in the toll lane that can show the amount paid, date,
                  time, and lane. These kinds of receipts are used in toll
                  booths or toll plaza that provides a receipt of the payment.
                </BulletRow>

                <BulletRow>
                  <strong>Internet/phone bill receipt: </strong> Internet
                  service providers take internet bills monthly, quarterly,
                  semi-annually, and annually. Sending a receipt to the users
                  can be a perfect custom instead of sending manual messages.
                </BulletRow>

                <BulletRow>
                  <strong>Hotel receipts: </strong> A hotel receipt template
                  documents hotel payments. It's essential because the hotel
                  guest may be able to use it as a tax write-off or employer
                  reimbursement. A hotel template should show the guest's name
                  and address, the hotel's name and address, the date the
                  receipt was provided, the length of stay, the employee issuing
                  the receipt, the total amount paid, and how the payment was
                  made.
                </BulletRow>

                <BulletRow>
                  <strong>Rent Receipt: </strong>A rent receipt confirms the
                  payment made by a tenant to a landlord. A tenant should
                  receive a monthly rent receipt after paying their rent
                  invoice. The rent receipt process follows a standard pattern.
                  After signing the lease, the tenant is aware of the amount
                  due. In most cases, rent is paid each month. However,
                  depending on the contract, there might be exclusions.
                </BulletRow>
              </BulletList>

              <TocH2>
                Generate Receipts with the online receipt maker Receiptmakerly
              </TocH2>
              <TocText>
                Receiptmakerly is a premium receipt maker software consisting of
                50+ templates. Using this tool, anyone can generate receipts for
                their businesses. Once the receipts are created, they can be
                downloaded in pdf or image and sent to the customers quickly.
              </TocText>
              <TocH3>Features of Receiptmakerly receipt maker:</TocH3>

              <BulletList>
                <BulletRow>
                  <strong>⚡50+ customizable receipt templates:</strong> You can
                  use any of the customizable templates for your business. Just
                  select the template and customize it according to your needs.
                  Once you have the customizable receipt templates, you can save
                  time by generating receipts within a short time. You do not
                  need to prepare a receipt at your own every time.
                </BulletRow>

                <BulletRow>
                  <strong>⚡ Automatic calculation, including Tax: </strong>
                  There are multiple tax types, and you can select the one you
                  need. The type of taxes available is TAX, VAT, Sales tax,
                  Service tax, IVA, PST, CGST, SGST, TPV, TPQ, SAR, and GST.
                  Just select any of these tax types and add the percentage.
                  Receiptmakerly will calculate the tax automatically for you.
                </BulletRow>

                <BulletRow>
                  <strong>⚡ Multiple currency options : </strong> There are
                  various currency options available. 19 different currency
                  types are available in Receiptmakerly. Select the currency
                  type and calculate the receipt amount.
                </BulletRow>

                <BulletRow>
                  <strong>⚡ Receipt generation in pdf and image: </strong>
                  Once the receipts are created in pdf and image, you can choose
                  the format you want to make.
                </BulletRow>

                <BulletRow>
                  <strong>⚡ One-click receipt download option : </strong>The
                  receipts you will create using Receiptmakerly will remain in
                  the app's history. In fact, you can keep the receipts stored
                  there and download them any time you want. Also, there is an
                  option to edit the receipt that you have already created.
                </BulletRow>

                <BulletRow>
                  <strong>⚡ Map location adding in the taxi receipts: </strong>{" "}
                  Generally, taxi receipts contain the option of adding
                  location. You need to add the location name, and that will be
                  added to the receipt automatically by this receipt maker.
                </BulletRow>

                <BulletRow>
                  <strong>⚡ Multiple fonts to use in the receipt: </strong>{" "}
                  Various fonts are available in the Receiptmakerly receipt
                  maker. You can choose the font you like and create beautiful
                  receipts using diversified fonts.
                </BulletRow>

                <BulletRow>
                  <strong>⚡ Date and time picker in the receipt: </strong> You
                  do not need to add the date and time manually. However, using
                  the date and time picker, you can place them easily.
                </BulletRow>
              </BulletList>

              <TocH2>To Sum Up</TocH2>
              <TocText>
                After all, receipts are important for businesses of every type.
                If you are a business owner, it’s time to take your business to
                the next level by generating meaningful receipts. Choose
                Receiptmakerly as your trusted partner to create receipts.
              </TocText>
            </StyledMain>
          </TocContainer>
        </Section>

        {/* <ImageSection>
          <ImageTitleH2>
            We Touched The Life Of{" "}
            <ColoredSpan color="#2D2D51"> 600+</ColoredSpan> Merchants’ Custom
            Development
          </ImageTitleH2>
          <CommonArrowButton>
            <div>Browse Apps</div>
            <ButtonArrow>
              <img
                src={buttonArrow}
                alt="arrow"
                style={{ marginLeft: `10px` }}
              />
            </ButtonArrow>
          </CommonArrowButton>
        </ImageSection> */}
      </Container>
    </>
  );
};

export default IndexPage;
