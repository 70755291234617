import React, { useState, useRef } from "react";
import Chevron from "./Chevron";
import faqCollapse from "../images/faq-collapse.png";
import faqExpand from "../images/faq-expand.png";
import "../styles/accordion.css";

const Accordion = (props) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <div className="accordion__section">
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className={`accordion__title ${setActive}`}>{props.title}</p>
        <div className="chevron-grid">
        <Chevron className={`${setRotate}`} width={10} fill={"#33cdd1"} />
          {/* <img className={`collapse-${setRotate}`}
            src={faqCollapse}
            alt="faq collapse"
            width="100%"
          />
          <img className={`expand-${setRotate}`}
            src={faqExpand}
            alt="faq expand"
            width="100%"
          /> */}
        </div>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  );
};
export default Accordion;
